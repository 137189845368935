<template>
  <section class="section is-medium has-text-centered">
    <div class="columns is-centered">
      <div class="column is-6">
        <h1 class="title is-1">
          {{ error.status }}
        </h1>
        <h2 class="subtitle is-3">
          {{ error.message }}
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Error',

  props: {
    status: {
      type: Number,
      default: 404,
    },
  },

  data() {
    return {
      errors: [
        {
          status: 404,
          message: 'Page not Found',
        },
        {
          status: 500,
          message: 'Internal Server Error',
        },
      ],
    };
  },

  computed: {
    error() {
      return this.errors.find(error => error.status === this.status);
    },
  },

  created() {
    document.documentElement.removeAttribute('class');
  },
};
</script>
